import Interceptor from 'auth/Interceptor'
import { API_BASE_URL } from 'configs/AppConfig';
import { initReactI18next } from 'react-i18next';
import { AUTH_TOKEN,USER_LOGED } from 'constants/AuthConstant';
import  secureLocalStorage  from  "react-secure-storage";

const AuthService = {}

AuthService.login = async function (data) {
	let respuesta = '';
    await fetch(API_BASE_URL+'/login',{
		method: 'post',
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json;charset=UTF-8",
			},
		body: JSON.stringify(data)
	})
	.then((resp) => resp.json())
	.then((resp) => {
		if(resp.status === 'success')
		{
			respuesta = resp
            let token = respuesta.authorisation.token;
			secureLocalStorage.setItem(AUTH_TOKEN, token);
			let usuario = resp.user;
			secureLocalStorage.setItem(USER_LOGED, JSON.stringify(usuario));
		}
		else if(resp.status === 'error')
		{
			console.log(resp.message)
			localStorage.setItem('errors', resp.message);
		}
        return resp;
	})
	.catch((err) => {
		console.log(err)
	});

    return respuesta;
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;