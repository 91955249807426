const dev = {
  API_ENDPOINT_URL: 'https://trackusuario.local/api',
  ENDPOINT_URL: 'https://trackusuario.local'
};

const prod = {
  API_ENDPOINT_URL: 'https://api.pledsos.life/api',
  ENDPOINT_URL: 'https://api.pledsos.life'
};

const test = {
  API_ENDPOINT_URL: 'https://api.pledsos.life/api'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			return prod
			break;
	}
}

export const env = getEnv()
