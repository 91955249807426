import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'log-in',
        path: `${AUTH_PREFIX_PATH}/log-in`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/dashboard')),
    },
    {
        key: 'pages.user-list',
        path: `${APP_PREFIX_PATH}/dashboard/personas`,
        component: React.lazy(() => import('views/app-views/usuarios')),
    },
    {
        key: 'pages.user-list',
        path: `${APP_PREFIX_PATH}/dashboard/personas/list`,
        component: React.lazy(() => import('views/app-views/usuarios/user-list')),
    },
    {
        key: 'pages.user-new',
        path: `${APP_PREFIX_PATH}/dashboard/personas/nuevo`,
        component: React.lazy(() => import('views/app-views/usuarios/user-form')),
    },
    {
        key: 'pages.user-edit',
        path: `${APP_PREFIX_PATH}/dashboard/personas/editar/:id`,
        component: React.lazy(() => import('views/app-views/usuarios/user-edit')),
    },
    {
        key: 'pages.pais-list',
        path: `${APP_PREFIX_PATH}/dashboard/paises`,
        component: React.lazy(() => import('views/app-views/paises/list')),
    },
    {
        key: 'pages.paises-new',
        path: `${APP_PREFIX_PATH}/dashboard/paises/nuevo`,
        component: React.lazy(() => import('views/app-views/paises/form')),
    },
    {
        key: 'pages.paises-edit',
        path: `${APP_PREFIX_PATH}/dashboard/paises/editar/:id`,
        component: React.lazy(() => import('views/app-views/paises/edit')),
    },
    {
        key: 'pages.estado-list',
        path: `${APP_PREFIX_PATH}/dashboard/estados`,
        component: React.lazy(() => import('views/app-views/estados/list')),
    },
    {
        key: 'pages.estados-new',
        path: `${APP_PREFIX_PATH}/dashboard/estados/nuevo`,
        component: React.lazy(() => import('views/app-views/estados/form')),
    },
    {
        key: 'pages.estados-edit',
        path: `${APP_PREFIX_PATH}/dashboard/estados/editar/:id`,
        component: React.lazy(() => import('views/app-views/estados/edit')),
    },
    {
        key: 'pages.espacio-list',
        path: `${APP_PREFIX_PATH}/dashboard/espacios`,
        component: React.lazy(() => import('views/app-views/espacios/list')),
    },
    {
        key: 'pages.espacios-new',
        path: `${APP_PREFIX_PATH}/dashboard/espacios/nuevo`,
        component: React.lazy(() => import('views/app-views/espacios/form')),
    },
    {
        key: 'pages.espacios-edit',
        path: `${APP_PREFIX_PATH}/dashboard/espacios/editar/:id`,
        component: React.lazy(() => import('views/app-views/espacios/edit')),
    },
    {
        key: 'pages.espacios-apartar',
        path: `${APP_PREFIX_PATH}/dashboard/espacios/apartar`,
        component: React.lazy(() => import('views/app-views/espacios/apartar')),
    },
    {
        key: 'pages.iglesia-list',
        path: `${APP_PREFIX_PATH}/dashboard/iglesias`,
        component: React.lazy(() => import('views/app-views/iglesias/list')),
    },
    {
        key: 'pages.iglesias-new',
        path: `${APP_PREFIX_PATH}/dashboard/iglesias/nuevo`,
        component: React.lazy(() => import('views/app-views/iglesias/form')),
    },
    {
        key: 'pages.iglesias-edit',
        path: `${APP_PREFIX_PATH}/dashboard/iglesias/editar/:id`,
        component: React.lazy(() => import('views/app-views/iglesias/edit')),
    },
    {
        key: 'pages.nivel-list',
        path: `${APP_PREFIX_PATH}/dashboard/niveles`,
        component: React.lazy(() => import('views/app-views/niveles/list')),
    },
    {
        key: 'pages.niveles-new',
        path: `${APP_PREFIX_PATH}/dashboard/niveles/nuevo`,
        component: React.lazy(() => import('views/app-views/niveles/form')),
    },
    {
        key: 'pages.niveles-edit',
        path: `${APP_PREFIX_PATH}/dashboard/niveles/editar/:id`,
        component: React.lazy(() => import('views/app-views/niveles/edit')),
    },
    {
        key: 'pages.ocupacion-list',
        path: `${APP_PREFIX_PATH}/dashboard/ocupaciones`,
        component: React.lazy(() => import('views/app-views/ocupaciones/list')),
    },
    {
        key: 'pages.ocupaciones-new',
        path: `${APP_PREFIX_PATH}/dashboard/ocupaciones/nuevo`,
        component: React.lazy(() => import('views/app-views/ocupaciones/form')),
    },
    {
        key: 'pages.ocupaciones-edit',
        path: `${APP_PREFIX_PATH}/dashboard/ocupaciones/editar/:id`,
        component: React.lazy(() => import('views/app-views/ocupaciones/edit')),
    },
    {
        key: 'pages.tipoevento-list',
        path: `${APP_PREFIX_PATH}/dashboard/tipoevento`,
        component: React.lazy(() => import('views/app-views/tipoEvento/list')),
    },
    {
        key: 'pages.tipoevento-new',
        path: `${APP_PREFIX_PATH}/dashboard/tipoevento/nuevo`,
        component: React.lazy(() => import('views/app-views/tipoEvento/form')),
    },
    {
        key: 'pages.tipoevento-edit',
        path: `${APP_PREFIX_PATH}/dashboard/tipoevento/editar/:id`,
        component: React.lazy(() => import('views/app-views/tipoEvento/edit')),
    },
    {
        key: 'pages.eventos-agenda',
        path: `${APP_PREFIX_PATH}/dashboard/agenda`,
        component: React.lazy(() => import('views/app-views/eventos/agenda')),
    },
    {
        key: 'pages.eventos-list',
        path: `${APP_PREFIX_PATH}/dashboard/eventos/list`,
        component: React.lazy(() => import('views/app-views/eventos/list')),
    },
    {
        key: 'pages.eventos-new',
        path: `${APP_PREFIX_PATH}/dashboard/eventos/nuevo`,
        component: React.lazy(() => import('views/app-views/eventos/form')),
    },
    {
        key: 'pages.eventos-edit',
        path: `${APP_PREFIX_PATH}/dashboard/eventos/editar/:id`,
        component: React.lazy(() => import('views/app-views/eventos/edit')),
    },
    {
        key: 'pages.eventos-detalles',
        path: `${APP_PREFIX_PATH}/dashboard/eventos/detalles/:id`,
        component: React.lazy(() => import('views/app-views/eventos/detalles')),
    },
    {
        key: 'pages.eventos-registros',
        path: `${APP_PREFIX_PATH}/dashboard/eventos/registros/:id`,
        component: React.lazy(() => import('views/app-views/eventos/registros')),
    },
    {
        key: 'pages.eventos-asistentes',
        path: `${APP_PREFIX_PATH}/dashboard/eventos/asistentes/:id`,
        component: React.lazy(() => import('views/app-views/eventos/asistentes')),
    },
    {
        key: 'pages.estadosciviles-list',
        path: `${APP_PREFIX_PATH}/dashboard/estadosciviles`,
        component: React.lazy(() => import('views/app-views/estadosciviles/list')),
    },
    {
        key: 'pages.estadosciviles-new',
        path: `${APP_PREFIX_PATH}/dashboard/estadosciviles/nuevo`,
        component: React.lazy(() => import('views/app-views/estadosciviles/form')),
    },
    {
        key: 'pages.estadosciviles-edit',
        path: `${APP_PREFIX_PATH}/dashboard/estadosciviles/editar/:id`,
        component: React.lazy(() => import('views/app-views/estadosciviles/edit')),
    },
    {
        key: 'pages.ministerios-list',
        path: `${APP_PREFIX_PATH}/dashboard/ministerios/list`,
        component: React.lazy(() => import('views/app-views/ministerios/list')),
    },
    {
        key: 'pages.ministerios',
        path: `${APP_PREFIX_PATH}/dashboard/ministerios`,
        component: React.lazy(() => import('views/app-views/ministerios')),
    },
    {
        key: 'pages.ministerios-new',
        path: `${APP_PREFIX_PATH}/dashboard/ministerios/nuevo`,
        component: React.lazy(() => import('views/app-views/ministerios/form')),
    },
    {
        key: 'pages.ministerios-edit',
        path: `${APP_PREFIX_PATH}/dashboard/ministerios/editar/:id`,
        component: React.lazy(() => import('views/app-views/ministerios/edit')),
    },
    {
        key: 'pages.ministerios-detalles',
        path: `${APP_PREFIX_PATH}/dashboard/ministerios/detalles/:id`,
        component: React.lazy(() => import('views/app-views/ministerios/detalles')),
    },
    {
        key: 'pages.encuestas-list',
        path: `${APP_PREFIX_PATH}/dashboard/encuestas`,
        component: React.lazy(() => import('views/app-views/encuestas/list')),
    },
    {
        key: 'pages.encuestas-new',
        path: `${APP_PREFIX_PATH}/dashboard/encuestas/nuevo`,
        component: React.lazy(() => import('views/app-views/encuestas/form')),
    },
    {
        key: 'pages.encuestas-edit',
        path: `${APP_PREFIX_PATH}/dashboard/encuestas/editar/:id`,
        component: React.lazy(() => import('views/app-views/encuestas/edit')),
    },
    {
        key: 'pages.preguntas-new',
        path: `${APP_PREFIX_PATH}/dashboard/preguntas/nuevo/:encuesta_id`,
        component: React.lazy(() => import('views/app-views/preguntas/form')),
    },
    {
        key: 'pages.preguntas-edit',
        path: `${APP_PREFIX_PATH}/dashboard/preguntas/editar/:id/:encuesta_id`,
        component: React.lazy(() => import('views/app-views/preguntas/edit')),
    },
    {
        key: 'pages.encuestas-preview',
        path: `${APP_PREFIX_PATH}/dashboard/encuestas/preview/:id`,
        component: React.lazy(() => import('views/app-views/encuestas/preview')),
    },
    {
        key: 'pages.encuestas-responder',
        path: `${APP_PREFIX_PATH}/dashboard/encuestas/responder/:id/:usuario_id`,
        component: React.lazy(() => import('views/app-views/encuestas/apply')),
    },
    {
        key: 'pages.encuestas-revisar',
        path: `${APP_PREFIX_PATH}/dashboard/encuestas/revisar/:id/:usuario_id`,
        component: React.lazy(() => import('views/app-views/encuestas/revisar')),
    },
    {
        key: 'pages.respuestas',
        path: `${APP_PREFIX_PATH}/dashboard/encuesta/respuestas/:id`,
        component: React.lazy(() => import('views/app-views/encuestas/respuestas')),
    },
    {
        key: 'pages.perfil',
        path: `${APP_PREFIX_PATH}/dashboard/perfil`,
        component: React.lazy(() => import('views/app-views/perfil')),
    },
    {
        key: 'pages.perfil.id',
        path: `${APP_PREFIX_PATH}/dashboard/perfil/:id`,
        component: React.lazy(() => import('views/app-views/perfil/edit')),
    },
    {
        key: 'pages.inventarios.id',
        path: `${APP_PREFIX_PATH}/dashboard/inventarios/list`,
        component: React.lazy(() => import('views/app-views/inventarios/list')),
    },
    {
        key: 'pages.inventarios-new',
        path: `${APP_PREFIX_PATH}/dashboard/inventarios/nuevo`,
        component: React.lazy(() => import('views/app-views/inventarios/form')),
    },
    {
        key: 'pages.inventarios-edit',
        path: `${APP_PREFIX_PATH}/dashboard/inventarios/editar/:id`,
        component: React.lazy(() => import('views/app-views/inventarios/edit')),
    },
    {
        key: 'pages.necesidades.id',
        path: `${APP_PREFIX_PATH}/dashboard/necesidades/:id`,
        component: React.lazy(() => import('views/app-views/necesidades/list')),
    },
    {
        key: 'pages.necesidades-new',
        path: `${APP_PREFIX_PATH}/dashboard/necesidades/nuevo`,
        component: React.lazy(() => import('views/app-views/necesidades/form')),
    },
    {
        key: 'pages.necesidades-edit',
        path: `${APP_PREFIX_PATH}/dashboard/necesidades/editar/:id`,
        component: React.lazy(() => import('views/app-views/necesidades/edit')),
    },
    {
        key: 'pages.configuraciones-campos',
        path: `${APP_PREFIX_PATH}/dashboard/configuraciones/campos`,
        component: React.lazy(() => import('views/app-views/configuraciones/campos')),
    },
    {
        key: 'pages.configuraciones-campos-new',
        path: `${APP_PREFIX_PATH}/dashboard/configuraciones/campos/nuevo`,
        component: React.lazy(() => import('views/app-views/configuraciones/campos/form')),
    },
    {
        key: 'pages.configuraciones-campos-edit',
        path: `${APP_PREFIX_PATH}/dashboard/configuraciones/campos/editar/:id`,
        component: React.lazy(() => import('views/app-views/configuraciones/campos/edit')),
    },
    {
        key: 'pages.familias',
        path: `${APP_PREFIX_PATH}/dashboard/familias`,
        component: React.lazy(() => import('views/app-views/familias')),
    },
    {
        key: 'pages.familias.id',
        path: `${APP_PREFIX_PATH}/dashboard/familias/list`,
        component: React.lazy(() => import('views/app-views/familias/list')),
    },
    {
        key: 'pages.familias-new',
        path: `${APP_PREFIX_PATH}/dashboard/familias/nuevo`,
        component: React.lazy(() => import('views/app-views/familias/form')),
    },
    {
        key: 'pages.familias-edit',
        path: `${APP_PREFIX_PATH}/dashboard/familias/editar/:id`,
        component: React.lazy(() => import('views/app-views/familias/edit')),
    },
    {
        key: 'pages.rolesfamilia.id',
        path: `${APP_PREFIX_PATH}/dashboard/rolesfamilia/:id`,
        component: React.lazy(() => import('views/app-views/rolesfamilia/list')),
    },
    {
        key: 'pages.rolesfamilia-new',
        path: `${APP_PREFIX_PATH}/dashboard/rolesfamilia/nuevo`,
        component: React.lazy(() => import('views/app-views/rolesfamilia/form')),
    },
    {
        key: 'pages.rolesfamilia-edit',
        path: `${APP_PREFIX_PATH}/dashboard/rolesfamilia/editar/:id`,
        component: React.lazy(() => import('views/app-views/rolesfamilia/edit')),
    },
    {
        key: 'pages.rolesfamilia-new',
        path: `${APP_PREFIX_PATH}/dashboard/espacios/croquis`,
        component: React.lazy(() => import('views/app-views/eventos/croquis')),
    },
    {
        key: 'pages.liderazgo-',
        path: `${APP_PREFIX_PATH}/dashboard/liderazgo`,
        component: React.lazy(() => import('views/app-views/liderazgo')),
    },
    {
        key: 'pages.liderazgo-list',
        path: `${APP_PREFIX_PATH}/dashboard/liderazgo/list`,
        component: React.lazy(() => import('views/app-views/liderazgo/list')),
    },
    {
        key: 'pages.liderazgo-new',
        path: `${APP_PREFIX_PATH}/dashboard/liderazgo/nuevo`,
        component: React.lazy(() => import('views/app-views/liderazgo/form')),
    },
    {
        key: 'pages.liderazgo-edit',
        path: `${APP_PREFIX_PATH}/dashboard/liderazgo/editar/:id`,
        component: React.lazy(() => import('views/app-views/liderazgo/edit')),
    },
    {
        key: 'pages.liderazgo-detalles',
        path: `${APP_PREFIX_PATH}/dashboard/liderazgo/detalles/:id`,
        component: React.lazy(() => import('views/app-views/liderazgo/detalles')),
    },
    {
        key: 'pages.dones',
        path: `${APP_PREFIX_PATH}/dashboard/donesespirituales`,
        component: React.lazy(() => import('views/app-views/dones')),
    },
    {
        key: 'pages.dones-list',
        path: `${APP_PREFIX_PATH}/dashboard/donesespirituales/list`,
        component: React.lazy(() => import('views/app-views/dones/list')),
    },
    {
        key: 'pages.dones-new',
        path: `${APP_PREFIX_PATH}/dashboard/donesespirituales/nuevo`,
        component: React.lazy(() => import('views/app-views/dones/form')),
    },
    {
        key: 'pages.dones-edit',
        path: `${APP_PREFIX_PATH}/dashboard/donesespirituales/editar/:id`,
        component: React.lazy(() => import('views/app-views/dones/edit')),
    },
    {
        key: 'pages.dones-detalles',
        path: `${APP_PREFIX_PATH}/dashboard/donesespirituales/detalles/:id`,
        component: React.lazy(() => import('views/app-views/dones/detalles')),
    },
    {
        key: 'pages.configuraciones-roles',
        path: `${APP_PREFIX_PATH}/dashboard/configuraciones/roles`,
        component: React.lazy(() => import('views/app-views/roles/list')),
    },
    {
        key: 'pages.configuraciones-roles-new',
        path: `${APP_PREFIX_PATH}/dashboard/configuraciones/roles/nuevo`,
        component: React.lazy(() => import('views/app-views/roles/form')),
    },
    {
        key: 'pages.configuraciones-roles-edit',
        path: `${APP_PREFIX_PATH}/dashboard/configuraciones/roles/editar/:id`,
        component: React.lazy(() => import('views/app-views/roles/edit')),
    },
    {
        key: 'pages.configuraciones-user-preview',
        path: `${APP_PREFIX_PATH}/dashboard/usuarios/preview/:id`,
        component: React.lazy(() => import('views/app-views/usuarios/preview')),
    },
    {
        key: 'pages.evento-asistencias',
        path: `${APP_PREFIX_PATH}/dashboard/eventos/asistenciaspdf/:id`,
        component: React.lazy(() => import('views/app-views/eventos/asistencias')),
    },
]