import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN,USER_LOGED } from 'constants/AuthConstant';
import  secureLocalStorage  from  "react-secure-storage";

const LoginService = {}

LoginService.login = async function(data)
{
    let token = '';
    await fetch(API_BASE_URL +'/login',{
		method: 'post',
		body: JSON.stringify(data),
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json;charset=UTF-8",
		  }
	})
	.then((resp) => resp.json())
	.then((resp) => {
		if(resp.status)
		{
			if(resp.status === 'success')
			{
				token = resp.authorisation.token;
				//localStorage.setItem(AUTH_TOKEN, token);
				secureLocalStorage.setItem(AUTH_TOKEN, token);
				let usuario = resp.user;
				//localStorage.setItem(USER_LOGED, JSON.stringify(usuario));
				secureLocalStorage.setItem(USER_LOGED, JSON.stringify(usuario));
			}
			else if(resp.status === 'error')
			{
				console.log(resp.message)
				localStorage.setItem('errors', resp.message);
			}
            
		}
		else{
			let error = ''
			resp.errors.map((error,i) => (
                error += error[0]  				
			))

			localStorage.setItem('errors',error);
		}
        return resp;
	})
	.catch((err) => {
		console.log(err)
	});

    return token;
    
}


export default LoginService;