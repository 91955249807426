import { AUTH_TOKEN } from 'constants/AuthConstant';
import  secureLocalStorage  from  "react-secure-storage";

const Interceptor = {}
const token = secureLocalStorage.getItem(AUTH_TOKEN) || '';
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json;charset=UTF-8",
  "Authorization": "Bearer " + token
  }

  const headersDownload = {
    "Authorization": "Bearer " + token,
		//'Content-Type': 'text/csv;charset=utf-8;',
    }

  const headerUpload = {
    "Authorization": "Bearer " + token
    }  

Interceptor.options = {
    headers: headers,
  };

Interceptor.optionsDownload = {
    headers: headersDownload
  };

Interceptor.optionsPost = {
    method: 'POST',  
    headers: headers,
    body:''
  };

Interceptor.optionsPut = {
    method: 'PUT',  
    headers: headers,
    body:''
  };

Interceptor.optionsUpload = {
    method: 'POST',  
    headers: headerUpload,
    body:''
  };

  Interceptor.optionsDelete = {
    method: 'DELETE',  
    headers: headers,
    body:''
  };

  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    let [resource, config] = args;
    let response = await originalFetch(resource, config);
    if (!response.ok && (response.status === 401 || response.status === 403 )){
      /*
      secureLocalStorage.removeItem(USER_LOGED);
	    secureLocalStorage.removeItem(AUTH_TOKEN);
      secureLocalStorage.clear()
		  store.dispatch(signOutSuccess())
      */
      console.error('Authentication Fail ' + response.status);
      return Promise.reject(response);
    }
    return response;
  };  

  export default Interceptor